const _jsxFileName = "/usr/src/app/app/js/components/BenchmarkHomeRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  hero: {
    padding: "100px 100px",
    textAlign: "center",
  },
  link: {
    color: theme.palette.success.main,
    textDecoration: "none",
    "&:visited": {
      color: theme.palette.success.main,
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export const BenchmarkHomeRoute = observer(function BenchmarkHomeRoute() {
  const app = useApp();
  const classes = useStyles();
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [hasCheck, setHasCheck] = useState(false);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       await app.benchmarks.fetchUserBenchmark();
  //     } catch (error) {
  //       console.log("Error when trying to fetch data from database");
  //     }
  //   };

  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   if (Object.keys(app.benchmarks.benchmarks).length !== 0) {
  //     setHasCheck(true);
  //   } else {
  //     setHasCheck(false);
  //   }
  // }, [hasCheck]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await app.benchmarks.fetchUserBenchmark();

        if (Object.keys(app.benchmarks.benchmarks).length !== 0) {
          setHasCheck(true);
        } else {
          setHasCheck(false);
        }
      } catch (error) {
        console.log("Error when trying to fetch data from database");
      }
    };

    fetchData();
  }, []);

  return (
    React.createElement(Grid, { container: true, justifyContent: "center", spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
      , React.createElement(Grid, { item: true, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
        , React.createElement('div', { className: classes.hero, __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
          , React.createElement(Typography, {
            variant: isSmallScreen ? "h4" : "h2",
            component: "h1",
            className: classes.marginBottom,
            style: { marginBottom: "2rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
, "Kreislaufwirtschaft: Wo steht Ihr Unternehmen?"

          )
          , React.createElement(Typography, {
            variant: "body1",
            className: classes.marginBottom,
            style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
, "Finden Sie anhand einer qualitativen Reifegradbestimmung heraus, wie zirkulär Ihr Unternehmen bereits aufgestellt ist."


          )
          , React.createElement(Typography, {
            variant: "body1",
            className: classes.marginBottom,
            style: { marginBottom: "3rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}
, "Erhalten Sie darauf basierend Handlungsempfehlungen zur Weiterentwicklung Ihres Unternehmens."


          )

          , hasCheck && !app.parse.user && (
            React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}
              , React.createElement(Typography, {
                variant: "body1",
                className: classes.marginBottom,
                style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}
, "Starten Sie einen neuen Kreislaufwirtschafts-Check"

              )

              , React.createElement(Button, {
                variant: "outlined",
                color: "primary",
                size: "large",
                onClick: () => {
                  app.benchmarks.createBenchmark().then((id) => {
                    navigate("/check/edit/meta");
                  });
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}}
, "Neuen Check starten"

              )
            )
          )

          , hasCheck && app.parse.user && (
            React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 143}}
              , React.createElement(Typography, {
                variant: "body1",
                className: classes.marginBottom,
                style: { marginBottom: "0.5rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 144}}
, "Führen Sie begonnene Kreislaufwirtschafts-Checks fort"

              )

              , React.createElement(Button, {
                variant: "contained",
                color: "primary",
                size: "large",
                onClick: () => navigate("/check/summary"),
                style: { marginBottom: "3rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 152}}
, "Zur Historie"

              )

              , React.createElement(Typography, {
                variant: "body1",
                className: classes.marginBottom,
                style: { marginBottom: "0.5rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}
, "Starten Sie einen neuen Kreislaufwirtschafts-Check"

              )

              , React.createElement(Button, {
                variant: "outlined",
                color: "primary",
                size: "large",
                onClick: () => {
                  if (
                    _optionalChain([app, 'access', _ => _.parse, 'access', _2 => _2.user, 'optionalAccess', _3 => _3.companyBranch]) &&
                    _optionalChain([app, 'access', _4 => _4.parse, 'access', _5 => _5.user, 'optionalAccess', _6 => _6.companySize]) &&
                    _optionalChain([app, 'access', _7 => _7.parse, 'access', _8 => _8.user, 'optionalAccess', _9 => _9.companyRevenue])
                  ) {
                    app.benchmarks
                      .createBenchmarkUser(
                        _optionalChain([app, 'access', _10 => _10.parse, 'access', _11 => _11.user, 'optionalAccess', _12 => _12.companyBranch]),
                        _optionalChain([app, 'access', _13 => _13.parse, 'access', _14 => _14.user, 'optionalAccess', _15 => _15.companySize]),
                        _optionalChain([app, 'access', _16 => _16.parse, 'access', _17 => _17.user, 'optionalAccess', _18 => _18.companyRevenue])
                      )
                      .then(() => {
                        navigate("/check/edit/finish");
                      });
                  } else {
                    app.benchmarks.createBenchmark().then(() => {
                      navigate("/check/edit/meta");
                    });
                  }
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 170}}
, "Neuen Check starten"

              )
            )
          )

          , !hasCheck && (
            React.createElement(Typography, { variant: "body1", __self: this, __source: {fileName: _jsxFileName, lineNumber: 202}}
              , React.createElement(Button, {
                variant: "contained",
                color: "primary",
                size: "large",
                className: classes.marginBottom,
                onClick: () => {
                  if (!app.parse.user) {
                    navigate("/userLogin");
                  } else {
                    if (
                      _optionalChain([app, 'access', _19 => _19.parse, 'access', _20 => _20.user, 'optionalAccess', _21 => _21.companyBranch]) &&
                      _optionalChain([app, 'access', _22 => _22.parse, 'access', _23 => _23.user, 'optionalAccess', _24 => _24.companySize]) &&
                      _optionalChain([app, 'access', _25 => _25.parse, 'access', _26 => _26.user, 'optionalAccess', _27 => _27.companyRevenue])
                    ) {
                      app.benchmarks
                        .createBenchmarkUser(
                          _optionalChain([app, 'access', _28 => _28.parse, 'access', _29 => _29.user, 'optionalAccess', _30 => _30.companyBranch]),
                          _optionalChain([app, 'access', _31 => _31.parse, 'access', _32 => _32.user, 'optionalAccess', _33 => _33.companySize]),
                          _optionalChain([app, 'access', _34 => _34.parse, 'access', _35 => _35.user, 'optionalAccess', _36 => _36.companyRevenue])
                        )
                        .then(() => {
                          navigate("/check/edit/finish");
                        });
                    } else {
                      app.benchmarks.createBenchmark().then(() => {
                        navigate("/check/edit/meta");
                      });
                    }
                  }
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 203}}
, "Jetzt starten!"

              )
            )
          )

          , React.createElement(Typography, {
            variant: "body1",
            className: classes.marginBottom,
            style: { marginBottom: "1rem", marginTop: "3rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 239}}
, "Der Kreislaufwirtschafts-Check ist im Rahmen einer Kooperation der Mittelstand-Digital Zentren WertNetzWerke und Darmstadt entstanden. Die Mittelstand-Digital Zentren WertNetzWerke und Darmstadt sind Teil der Förderinitiative Mittelstand-Digital."




          )
          , React.createElement(Typography, {
            variant: "body1",
            className: classes.marginBottom,
            style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 249}}
, "Mit Mittelstand-Digital unterstützt das Bundesministerium für Wirtschaft und Klimaschutz (BMWK) die Digitalisierung in kleinen und mittleren Unternehmen und dem Handwerk."



          )
        )
      )

      , Object.values(app.benchmarks.benchmarks).length > 0 &&
        !app.parse.user &&
        hasCheck && (
          React.createElement(Grid, { item: true, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 264}}
            , React.createElement(Paper, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 265}}
              , React.createElement(List, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 266}}
                , Object.values(app.benchmarks.benchmarks)
                  .sort(
                    (a, b) =>
                      new Date(b.createdAt).valueOf() -
                      new Date(a.createdAt).valueOf()
                  )
                  .map((benchmark) => {
                    const date = new Intl.DateTimeFormat("de-DE").format(
                      new Date(benchmark.createdAt)
                    );

                    const time = new Intl.DateTimeFormat("de-DE", {
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(new Date(benchmark.createdAt));

                    const answerCount = Object.keys(
                      benchmark.answers || {}
                    ).length;

                    const questionCount = app.benchmarks.questions.length;

                    const onClick = () => {
                      app.benchmarks.currentBenchmark = benchmark.objectId;
                      navigate("/check/edit");
                    };

                    return (
                      React.createElement(ListItem, {
                        key: benchmark.objectId,
                        button: true,
                        onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 295}}

                        , React.createElement(ListItemText, {
                          primary: `Beispiel-KLW-Check gestartet am ${date} um ${time}`,
                          secondary: 
                            React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 303}}
                              , answerCount, " / "  , questionCount, " Fragen beantwortet"
                            )
                          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 300}}
                        )
                        , React.createElement(ListItemSecondaryAction, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 308}}
                          , React.createElement(Button, {
                            variant: "outlined",
                            size: "small",
                            color: "primary",
                            onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 309}}
, "Fortsetzen"

                          )
                        )
                      )
                    );
                  })
              )
            )
          )
        )
    )
  );
});
