const _jsxFileName = "/usr/src/app/app/js/components/ContactRoute.tsx";import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import {
  Grid,
  makeStyles,
  Typography,
  Card,
  CardContent,
  Button,
  CircularProgress,
} from "@material-ui/core";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
    textAlign: "left",
  },
  padding: {
    padding: theme.spacing(2),
  },
  logo: {
    marginTop: "0.5rem",
  },
  contact: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "center",
    height: "100px",
    margin: "0.5rem",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
}));

export const ContactRoute = observer(function ContactRoute() {
  const app = useApp();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handleDownload(fileName, displayFileName) {
    try {
      setLoading(true);
      const { url } = await app.parse.download(fileName);

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Failed to download the file");
      }

      const arrayBuffer = await response.arrayBuffer();
      const blob = new Blob([arrayBuffer], { type: "application/zip" });

      const link = document.createElement("a");

      const objectURL = URL.createObjectURL(blob);
      link.href = objectURL;

      link.setAttribute("download", displayFileName);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(objectURL);
    } catch (error) {
      console.error("Error downloading the file:", error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    React.createElement(Grid, { container: true, justifyContent: "center", spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
      , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
        , React.createElement(Typography, {
          variant: "h4",
          component: "h1",
          className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}

          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}, "Ihr Kontakt zu uns"   )
        )
      )

      , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
        , React.createElement(Grid, { container: true, spacing: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
          , React.createElement(Grid, { item: true, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
            , React.createElement(Grid, { container: true, justifyContent: "center", spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
              , React.createElement(Card, { style: { height: "100%", width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
                , React.createElement(CardContent, { style: { height: "100%", width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}, "Sie haben Fragen oder interessieren sich für die Durchführung der Workshops? "

                    , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 103}} ), "Kontaktieren Sie uns gerne:"

                  , React.createElement(Grid, { container: true, spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
                    , React.createElement(Grid, { item: true, xs: 12, sm: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}
                      , React.createElement(Typography, {
                        variant: "body2",
                        component: "h2",
                        style: { marginBottom: "1rem", textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}

                        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
                          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}, "Dr. Anja Linnemann"  )
                          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 114}} ), "E-Mail:"
                          , " "
                          , React.createElement('a', {
                            href: "mailto:anja.linnemann@fit.fraunhofer.de",
                            style: {
                              color: "rgb(205, 10, 30)",
                              textDecoration: "none",
                            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}

                            , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}, "anja.linnemann@fit.fraunhofer.de")
                          ), " "
                        )
                      )
                    )
                    , React.createElement(Grid, { item: true, xs: 12, sm: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 128}}
                      , React.createElement(Typography, {
                        variant: "body2",
                        component: "h2",
                        style: { marginBottom: "1rem", textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}

                        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
                          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 135}}, "Jonas Barth M. Sc."   )
                          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 136}} ), "E-Mail:"
                          , " "
                          , React.createElement('a', {
                            href: "mailto:j.barth@ptw.tu-darmstadt.de",
                            style: {
                              color: "rgb(205, 10, 30)",
                              textDecoration: "none",
                            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}

                            , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 145}}, "j.barth@ptw.tu-darmstadt.de")
                          ), " "
                        )
                      )
                    )
                    , React.createElement(Grid, { item: true, xs: 12, sm: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 150}}
                      , React.createElement(Typography, {
                        variant: "body2",
                        component: "h2",
                        style: { marginBottom: "1rem", textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}

                        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 156}}
                          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 157}}, "Lisa Schlömer, Dipl.-Wirt.-Inf. (DH)"   )
                          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 158}} ), "E-Mail:"
                          , " "
                          , React.createElement('a', {
                            href: "mailto:lisa.schloemer@fit.fraunhofer.de",
                            style: {
                              color: "rgb(205, 10, 30)",
                              textDecoration: "none",
                            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}}

                            , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 167}}, "lisa.schloemer@fit.fraunhofer.de")
                          ), " "
                        )
                      )
                    )
                    , React.createElement(Grid, { item: true, xs: 12, sm: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 172}}
                      , React.createElement(Typography, {
                        variant: "body2",
                        component: "h2",
                        style: { marginBottom: "1rem", textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 173}}

                        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}
                          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 179}}, "Enno Lang M. Sc."   )
                          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 180}} ), "E-Mail:"
                          , " "
                          , React.createElement('a', {
                            href: "mailto:e.lang@ptw.tu-darmstadt.de",
                            style: {
                              color: "rgb(205, 10, 30)",
                              textDecoration: "none",
                            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 182}}

                            , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 189}}, "e.lang@ptw.tu-darmstadt.de")
                          ), " "
                        )
                      )
                    )
                  ), "Hier finden Sie alle Unterlagen der Workshops zur eigene Nutzung:"


                  , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 197}} )
                  , React.createElement(Button, {
                    variant: "text",
                    color: "primary",
                    size: "small",
                    startIcon: React.createElement(GetAppRoundedIcon, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 202}} ),
                    style: {
                      paddingLeft: 0,
                    },
                    onClick: () =>
                      handleDownload("Workshop Datei", "workshop_dateien.zip")
                    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 198}}
, "Download"

                  )
                  , loading && (
                    React.createElement('div', {
                      style: {
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1rem",
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 213}}

                      , React.createElement(CircularProgress, {
                        size: 24,
                        style: { marginRight: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 220}}
                      )
                      , React.createElement(Typography, { variant: "body2", color: "textSecondary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 224}}, "Ihre Dateien werden in Kürze heruntergeladen..."

                      )
                    )
                  )
                )
              )
            )
          )
        )
      )

      , React.createElement(Card, { style: { height: "100%", width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 236}}
        , React.createElement(CardContent, { style: { height: "100%", width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 237}}, "Sie sind an weiteren Angeboten der Mittelstand-Digital Zentren interessiert?"


          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 240}} ), " Besuchen Sie die Webauftritte, um Ansprechpartner:innen für Ihre Themen zu finden:"

          , React.createElement(Grid, { container: true, spacing: 3, className: classes.logo, __self: this, __source: {fileName: _jsxFileName, lineNumber: 242}}
            , React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 243}}
              , React.createElement('a', { href: "https://digitalzentrum-darmstadt.de/", target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 244}}
                , React.createElement('img', {
                  src: require("../assets/logo/mdz-darmstadt.png"),
                  alt: "Mittelstand-Digitalzentrum Darmstadt" ,
                  height: "100%",
                  width: "100%", __self: this, __source: {fileName: _jsxFileName, lineNumber: 245}}
                )
              )
            )
            , React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 253}}
              , React.createElement('a', {
                href: "https://www.mittelstand-digital-wertnetzwerke.de/",
                target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 254}}

                , React.createElement('img', {
                  src: require("../assets/logo/mdz-wnw.png"),
                  alt: "Mittelstand-Digitalzentrum WertNetZWerke" ,
                  height: "100%",
                  width: "100%", __self: this, __source: {fileName: _jsxFileName, lineNumber: 258}}
                )
              )
            )
          )
        )
      )
    )
  );
});
